import React from 'react'
import Image from '../image'
const Card = props => {
  const item = props.item
  return (
    <div className='bg-white rounded-lg overflow-hidden shadow relative'>
      <Image
        name={item.img}
        alt={item.title}
        width=''
        height=''
        className='h-56 w-full object-cover object-center'
      />
      <div className='p-4 h-auto md:h-40 lg:h-48'>
        {/* Note that external links still use `a` tags. */}
        <a
          href={item.url}
          target='_blank'
          rel='noreferrer'
          className='block text-orange-500 hover:text-orange-600 font-semibold mb-2 text-lg md:text-base lg:text-lg'
        >
          {item.name}
        </a>
        <div className='text-gray-600 text-sm leading-relaxed block md:text-xs lg:text-sm'>
          {item.description}
        </div>
        <div className='relative mt-2 lg:absolute bottom-0 mb-4 lg:block'>
          {item.tags.map(tag => {
            return (
              <p
                className={`inline bg-${tag.bg_color} py-1 px-2 rounded-full text-xs lowercase text-${tag.color} ml-1 mr-1`}
                key={tag.id}
              >
                {tag.name}
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Card
