import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Card from '../components/Card'
import clientData from '../assets/data/clients.json'

const Clients = () => {
  const dataCardClients = clientData.cardClients
  return (
    <Layout>
      <SEO
        title='Nuestros Clientes'
        description='Conoce el listado de nuestros clientes y mira lo que hemos realizado con ellos, únete para crear algo nuevo'
      />
      {/* Begin Title */}
      <section className='bg-gray-900'>
        <div className='container bg-grey-lightest mx-auto shadow rounded pb-4 bg-cover'>
          <div className='mt-2 p-4 border-b border-grey-light  text-center'>
            <h2 className='xs:text-base sm:text-3xl text-4xl font-thin text-white'>
              Nuestros Clientes
            </h2>
          </div>
        </div>
      </section>
      {/* Begin Clients */}
      <section className='mt-4 mb-4'>
        <div className='container mx-auto flex flex-wrap pt-4 pb-12'>
          {dataCardClients.map(item => {
            return (
              <div
                className='w-1/2 xs:w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink'
                key={item.id}
              >
                <Card item={item} />
              </div>
            )
          })}
          <div className='w-1/2 xs:w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink'></div>
        </div>
      </section>
    </Layout>
  )
}

export default Clients
